/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


      //Search Toggle OpenClose
      $( ".js--bpl-search-trigger" ).click(function() {
        $( '.bpl-search-overlay-wrapper' ).addClass( "bpl-search-overlay-wrapper--is-open" );
      });

      $( ".js-bpl-close-search-trigger" ).click(function() {
        $( '.bpl-search-overlay-wrapper' ).removeClass( "bpl-search-overlay-wrapper--is-open" );
      });

      //Search
      (function($) {
      var catalogSearchURL = $('.header--searchform').attr('action'), input;
      
      $('.header--searchform').on('submit', function(){
        var $selectedRadio = $(this).find('input[name="search_select"]:checked');
        if ( $selectedRadio.attr('id') === 'button_site' ) {
          $(this).closest("form").attr('action', '/');
            $(this).closest("form").attr('target', '_self');
        }
        else {
          $(this).closest("form").attr('action', catalogSearchURL);
            $(this).closest("form").attr('target', '_blank');
        }
      });
      
        $(".button_catalog").click(".button_catalog", function() {
          console.log('catalog buton clicked');
          $(this).closest("form").attr('action', catalogSearchURL);
          $(this).closest("form").attr('target', '_blank');
          //$("#s").val('');
          $('.libby-s').attr('placeholder', 'Search the catalog');
        });
        
        $(".button_site").click(function() {
        console.log('website buton clicked');
          $(this).closest("form").attr('action', '/');
          $(this).closest("form").attr('target', '_self');
          //$("#s").val('');
          $('input.libby-s').attr('placeholder', 'Search the website');
        });
      })(jQuery);

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
        //Create Owl Carousel for Hero
        jQuery('.ssilvia_hero_slider').owlCarousel({
          items: 1,
          loop: true,
          lazyLoad: true,
          dots: true,
          dotsEach: true,
          autoPlay: true,
          responsive: false,
          autoPlayTimeout: 1000,
          autoplayHoverPause: true,
          nav: true
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // YA page
    'young_adult': {
      init: function() {
        // JavaScript to be fired on the YA page
        
        //Create Owl Carousel for Hero
        jQuery('.bpl_hero_lg_slider').owlCarousel({
          items: 1,
          loop: true,
          lazyLoad: true,
          dots: true,
          dotsEach: true,
          autoPlay: true,
          responsive: false,
          autoPlayTimeout: 1000,
          autoplayHoverPause: true,
          nav: true
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Children
    'children': {
      init: function() {
        // JavaScript to be fired on the about us page
        //Create Owl Carousel for Hero
        jQuery('.bpl_hero_lg_slider').owlCarousel({
          items: 1,
          loop: true,
          lazyLoad: true,
          dots: true,
          dotsEach: true,
          autoPlay: true,
          responsive: false,
          autoPlayTimeout: 1000,
          autoplayHoverPause: true,
          nav: true
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
